import {
	FavouriteApplication,
	FavouriteApplicationStatus,
	FavouriteAssessments,
	Wishlist,
} from '@studyportals/wishlist-service-core';
import PromiseQueue from 'promise-queue';
import { IWishlistService } from '../../../interfaces';

export class QueueWishlistService implements IWishlistService {
	private queue: PromiseQueue;
	private wishlistService: IWishlistService;

	constructor() {
		this.queue = new PromiseQueue(1, Infinity);
	}

	setWishlistService(wishlistService: IWishlistService): void {
		this.wishlistService = wishlistService;
	}

	getWishlist(): Promise<Wishlist> {
		return this.queue.add(() => {
			return this.wishlistService.getWishlist();
		});
	}

	addFavourite(studyId: number): Promise<void> {
		return this.queue.add(() => {
			return this.wishlistService.addFavourite(studyId);
		});
	}

	removeFavourite(studyId: number): Promise<void> {
		return this.queue.add(() => {
			return this.wishlistService.removeFavourite(studyId);
		});
	}

	public setOrder(targetStudyId: number, position: number): Promise<void> {
		return this.queue.add(() => {
			return this.wishlistService.setOrder(targetStudyId, position);
		});
	}

	public setFavouriteApplicationStatus(studyId: number, applicationStatus: FavouriteApplicationStatus): Promise<void> {
		return this.queue.add(() => {
			return this.wishlistService.setFavouriteApplicationStatus(studyId, applicationStatus);
		});
	}

	public setFavouriteApplication(studyId: number, application: FavouriteApplication | null): Promise<void> {
		return this.queue.add(() => {
			return this.wishlistService.setFavouriteApplication(studyId, application);
		});
	}

	public setFavouriteAssessments(studyId: number, assessments: FavouriteAssessments): Promise<void> {
		return this.queue.add(() => {
			return this.wishlistService.setFavouriteAssessments(studyId, assessments);
		});
	}
}
