import {
	FavouriteApplication,
	FavouriteApplicationStatus,
	FavouriteAssessments,
	IncorrectWishlistState,
	Wishlist,
	WishlistNotFound,
} from '@studyportals/wishlist-service-core';

import { IWishlistService } from '../../../interfaces/wishlist-service';

export class RetryCommandWishlistService implements IWishlistService {
	constructor(private wishlistService: IWishlistService) {}

	public async addFavourite(studyId: number): Promise<void> {
		try {
			await this.wishlistService.addFavourite(studyId);
		} catch (error) {
			if (error instanceof IncorrectWishlistState || error instanceof WishlistNotFound) {
				return this.wishlistService.addFavourite(studyId);
			}

			throw error;
		}
	}

	public async removeFavourite(studyId: number): Promise<void> {
		try {
			await this.wishlistService.removeFavourite(studyId);
		} catch (error) {
			if (error instanceof IncorrectWishlistState) {
				return this.wishlistService.removeFavourite(studyId);
			}

			throw error;
		}
	}

	public async getWishlist(): Promise<Wishlist> {
		return this.wishlistService.getWishlist();
	}

	public async setOrder(targetStudyId: number, position: number): Promise<void> {
		return this.wishlistService.setOrder(targetStudyId, position);
	}

	public setFavouriteApplicationStatus(studyId: number, applicationStatus: FavouriteApplicationStatus): Promise<void> {
		return this.wishlistService.setFavouriteApplicationStatus(studyId, applicationStatus);
	}

	public setFavouriteApplication(studyId: number, application: FavouriteApplication | null): Promise<void> {
		return this.wishlistService.setFavouriteApplication(studyId, application);
	}

	public setFavouriteAssessments(studyId: number, assessments: FavouriteAssessments): Promise<void> {
		return this.wishlistService.setFavouriteAssessments(studyId, assessments);
	}
}
