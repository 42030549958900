import { IUser } from '../../../../interfaces/session-management/user.interface';

export class Student implements IUser {
	public readonly identityId: string;
	public readonly email: string;
	constructor(identityId: string, email: string) {
		this.identityId = identityId;
		this.email = email;
	}
}
