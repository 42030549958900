export class Deferred {
	private isOpen = false;
	private isOpeningPromisePresent: any | Promise<void> = false;
	private isReadyCallback: any = () => undefined;

	public open() {
		this.isOpen = true;
		this.isReadyCallback();
		this.reset();
	}

	public close() {
		this.isOpen = false;
		this.reset();
	}

	private reset() {
		this.isReadyCallback = () => undefined;
		this.isOpeningPromisePresent = false;
	}

	public untilOpen(): Promise<any> {
		// If not open, start opening, otherwise wait
		return this.isOpeningPromisePresent
			? this.isOpeningPromisePresent
			: (this.isOpeningPromisePresent = new Promise((resolve) => {
					// If ready - resolve, if not - set resolve to
					this.isOpen ? resolve(null) : (this.isReadyCallback = resolve);
				}));
	}
}
