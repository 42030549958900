import { IServerTime } from './server-time.interface';
import { ILocalStorageDriver } from '../../../common/local-storage-driver.interface';
import { DataStorageDriver } from '../../../common/data-storage-driver';
import FetchWrapper from '../../../../libs/FetchWrapper';
import { ICachedTime } from './cached-time.interface';

export class ServerTime implements IServerTime {
	cachedTime: ICachedTime = {
		time: null,
		offset: null,
	};
	storage: ILocalStorageDriver = new DataStorageDriver();
	storageKey: string = 'SessionService/CachedTime';
	apiEndpoint: string = 'https://worldtimeapi.org/api/timezone/Etc/GMT';

	private get fetch() {
		return FetchWrapper;
	}

	private async syncDate(): Promise<void> {
		try {
			const response = await this.fetch(this.apiEndpoint, {});
			const date = await response.json();

			this.storeTimeInCache({
				time: date.unixtime,
				offset: Math.round(date.unixtime - this.getCurrentTime()),
			});
		} catch (e) {
			this.storeTimeInCache({
				time: this.getCurrentTime(),
				offset: 0,
			});

			console.error('Failed to fetch server time: ', e);
		}

		this.cachedTime = JSON.parse(await this.storage.get(this.storageKey));
	}

	public async now(): Promise<number> {
		const cachedTime = await this.storage.get(this.storageKey);

		if (!cachedTime) {
			await this.syncDate();
		} else {
			this.cachedTime = JSON.parse(cachedTime);
		}

		return this.getCurrentTime() + this.cachedTime.offset;
	}

	private async storeTimeInCache(time: ICachedTime): Promise<void> {
		const monthInSeconds = 2592000;

		await this.storage.set(this.storageKey, JSON.stringify(time), monthInSeconds);
	}

	private getCurrentTime(): number {
		//Divided by 1000 to convert the timestamp from milliseconds to seconds
		return Math.round(Date.now() / 1000);
	}
}
