import { IEventAggregationService } from '@studyportals/event-aggregation-service-interface';
import {
	ISessionService,
	SessionCreatedEvent,
	SessionDestroyedEvent,
	SessionServiceReadyEvent,
} from '../../../interfaces';
import { CallbackSubscriber } from '../../event-aggregation-service/callback-subscriber';
import { CatchReportAsyncException, CatchReportException } from '../decorators/error-decorator';
import { TokenBasedSession } from '../domain/entities/token-based-session';
import { BrowserSessionStorage } from '../infrastructure/browser/browser-session-storage';
import { CallbackSubscriberLegacy } from './callback-subscriber-legacy';
import { Deferred } from './classes/deferred';
import JwtAccessError from './classes/errors/jwt-access-error';
import { StudentapiDriver } from './classes/studentapi-driver';

/**
 * @deprecated
 */
export default class AuthController {
	private sessionService: ISessionService;
	private studentApiDriver: StudentapiDriver;
	private deferred: Deferred;
	private eventAggregationService: IEventAggregationService;
	private browserSessionStorage: BrowserSessionStorage;
	// tslint:disable-next-line:typedef
	private browserSessionStorageKey = 'LoggedInStatus';
	private subscribers: CallbackSubscriberLegacy[];

	// tslint:disable-next-line:typedef
	private isLoggedInStatus = false;

	constructor(
		sessionService: ISessionService,
		studentApiDriver: StudentapiDriver,
		eventAggregationService: IEventAggregationService,
		browserSessionStorage: BrowserSessionStorage,
	) {
		this.sessionService = sessionService;
		this.studentApiDriver = studentApiDriver;
		this.eventAggregationService = eventAggregationService;
		this.browserSessionStorage = browserSessionStorage;
		this.deferred = new Deferred();
		this.subscribers = [];
	}

	@CatchReportException
	public initialize(): void {
		this.eventAggregationService.subscribeTo(
			SessionServiceReadyEvent.EventType,
			new CallbackSubscriber(() => this.onSessionServiceReady()),
			true,
		);
		this.eventAggregationService.subscribeTo(
			SessionCreatedEvent.EventType,
			new CallbackSubscriber(async () => this.onSessionCreated()),
		);
		this.eventAggregationService.subscribeTo(
			SessionDestroyedEvent.EventType,
			new CallbackSubscriber(async () => this.onSessionDestroyed()),
		);
	}

	@CatchReportAsyncException
	private async onSessionServiceReady(): Promise<void> {
		const session = await this.sessionService.getSession();

		this.setLoginStatus(session !== null);

		this.deferred.open();
	}

	@CatchReportException
	private onSessionCreated(): void {
		this.setLoginStatus(true);
		this.notifySubscribers();
	}

	@CatchReportException
	private onSessionDestroyed(): void {
		this.setLoginStatus(false);
		this.notifySubscribers();
	}

	/**
	 * @deprecated
	 */
	@CatchReportException
	private notifySubscribers(): void {
		this.subscribers.forEach((subscriber) => {
			subscriber.notify(null, { LoggedInStatus: this.isLoggedInStatus });
		});
	}

	/**
	 * @deprecated
	 * @param {(error, data) => void} callback
	 * @returns {Promise<void>}
	 */
	@CatchReportAsyncException
	public async subscribe(callback: (error: any, data: any) => void): Promise<void> {
		await this.deferred.untilOpen();

		const subscriber = new CallbackSubscriberLegacy(callback);

		subscriber.notify(null, {
			LoggedInStatus: this.isLoggedInStatus,
		});

		this.subscribers.push(subscriber);
	}

	/**
	 * @deprecated
	 * @returns {Promise<TokenBasedSession>}
	 */
	private async getTokenSession(): Promise<TokenBasedSession> {
		await this.deferred.untilOpen();
		const session = (await this.sessionService.getSession()) as TokenBasedSession;

		if (session === null) {
			throw new JwtAccessError('No session exists');
		}

		return session;
	}

	/**
	 * Send forgot password email
	 *
	 * @param email string
	 * @returns {Promise<object>}
	 */
	@CatchReportAsyncException
	public async forgotPassword(email: string): Promise<object> {
		return this.studentApiDriver.resetPassword(email);
	}

	/**
	 * @deprecated
	 * @returns {Promise<void>}
	 */
	@CatchReportAsyncException
	public async sessionLogout(): Promise<void> {
		await this.deferred.untilOpen();
		const session = await this.getTokenSession();

		return session.destroy();
	}

	/**
	 * @returns {Promise<void>}
	 */
	@CatchReportAsyncException
	public async subjectLogout(): Promise<void> {
		await this.deferred.untilOpen();
		return this.sessionService.globalLogout();
	}

	/**
	 * @deprecated Will be fased out use sign method on the session
	 * @returns {Promise<string>}
	 */
	@CatchReportAsyncException
	public async getAccessToken(): Promise<string> {
		await this.deferred.untilOpen();
		const session = await this.getTokenSession();

		return session.getAccessToken();
	}

	/**
	 * @deprecated Will be outfaced use user on Session
	 * @returns {Promise<string>}
	 */
	@CatchReportAsyncException
	public async getIdentityId(): Promise<string> {
		await this.deferred.untilOpen();
		const session = await this.getTokenSession();

		return session.getUser().identityId;
	}

	/**
	 * @deprecated Will be outfaced use user on Session
	 * @returns {Promise<string>}
	 */
	@CatchReportAsyncException
	public async getEmail(): Promise<string> {
		await this.deferred.untilOpen();
		const session = await this.getTokenSession();

		return session.getUser().email;
	}

	@CatchReportException
	public isLoggedIn(): boolean {
		return this.browserSessionStorage.getItem(this.browserSessionStorageKey) === 'true';
	}

	private setLoginStatus(status: boolean): void {
		this.isLoggedInStatus = status;
		this.browserSessionStorage.setItem(this.browserSessionStorageKey, this.isLoggedInStatus);
	}
}
