import { Actions, Labels } from '../../../../libs/Tracking/Tracking';
import AuthController from '../../../session-management/auth-controller/auth-controller';
import JwtRefreshError from '../../../session-management/auth-controller/classes/errors/jwt-refresh-error';
import { InterestType } from '../../enums/interest-type.enum';
import { RestCallType } from '../../enums/restcall-type.enum';
import { IStudentapiDriver } from '../../interfaces/i-studentapi-driver';

export class StudentapiDriver implements IStudentapiDriver {
	constructor(baseUrl: string, authController: AuthController, fetch: Function, trackError: Function) {
		this.baseUrl = baseUrl;
		this.authController = authController;
		this.fetch = fetch;
		this.trackError = trackError;
	}

	private authController;
	private baseUrl: string;
	private fetch: Function;
	private trackError: Function;

	/**
	 * Gets user interests
	 * @param {InterestType} type
	 * @returns {promise} Resolves with array.
	 */
	public getInterests(type: InterestType): Promise<number[]> {
		const interestFieldName = StudentapiDriver.getInterestFieldName(type);
		return this.sendRequest(RestCallType.GET, `/data?scope=${interestFieldName}`, {}).then((response) => {
			let interests = response[interestFieldName] == null ? [] : response[interestFieldName];
			return interests;
		});
	}

	/**
	 * Add interests and returns array of changed ids
	 * @param {string} type
	 * @param {number[]} ids
	 * @returns {Promise<Array<Number>>}
	 */
	public addInterests(type: string, ids: number[]): Promise<number[]> {
		let fieldName = StudentapiDriver.getInterestFieldName(type);

		let data = {};
		data[fieldName + '_add'] = ids;

		// TODO: check if field name exists
		return this.sendRequest(RestCallType.PATCH, '/data', data).then((data) => {
			return data[fieldName];
		});
	}

	/**
	 * Remove interests and returns array of changed ids
	 * @param {string} type
	 * @param {number[]} ids
	 * @returns {Promise<Array<Number>>}
	 */
	public removeInterests(type: string, ids: number[]): Promise<number[]> {
		let fieldName = StudentapiDriver.getInterestFieldName(type);

		let data = {};
		data[fieldName + '_remove'] = ids;

		return this.sendRequest(RestCallType.PATCH, '/data', data).then((data) => {
			return data[fieldName];
		});
	}

	/**
	 * Remove disciplines and returns array of changed ids
	 * @param {number[]} ids
	 * @returns {Promise<Array<Number>>}
	 */
	public removeDisciplines(ids: number[]): Promise<number[]> {
		let data = { disciplines_remove: ids };

		return this.sendRequest(RestCallType.PATCH, '/data', data).then((data) => {
			return data['disciplines'];
		});
	}

	/**
	 * add disciplines and returns array of changed ids
	 * @param {number[]} ids
	 * @returns {Promise<Array<Number>>}
	 */
	public addDisciplines(ids: number[]): Promise<number[]> {
		let data = { disciplines_add: ids };

		return this.sendRequest(RestCallType.PATCH, '/data', data).then((data) => {
			return data['disciplines'];
		});
	}

	/**
	 * Gets user interests
	 * @returns {promise} Resolves with array.
	 */
	public getDisciplines(): Promise<number[]> {
		return this.sendRequest(RestCallType.GET, `/data?scope=disciplines`, {}).then((response) => {
			return response['disciplines'] == null ? [] : response['disciplines'];
		});
	}

	public sendRequest(method: string, uri: string, data: object) {
		return this.authController
			.getAccessToken()
			.then((jwt) => {
				let options = {
					method,
					headers: {
						'Content-Type': 'application/json',
						Authorization: jwt,
					},
				};

				if (method.toUpperCase() !== RestCallType.GET && data) {
					options['body'] = JSON.stringify(data);
				}

				let isResponseOK = false;
				const fetchUrl = this.baseUrl + uri;
				const studentsAPIErrorParams = {
					action: Actions.StudentsAPIRequest,
					label: Labels.StudentsAPIError,
					payload: {
						fetchUrl,
						options,
					},
				};
				return this.fetch(fetchUrl, options)
					.then((response) => {
						isResponseOK = response.ok;
						return response.json();
					})
					.then((response) => {
						if (!isResponseOK) {
							this.trackError(response, studentsAPIErrorParams);
						}
						return response;
					})
					.catch((error) => this.trackError(error, studentsAPIErrorParams));
			})
			.catch((error) => {
				if (error instanceof JwtRefreshError) {
					this.trackError(error, {
						action: Actions.JWTAuth,
						label: Labels.JWTAuthError,
					});
				}
				throw error;
			});
	}

	/**
	 * Update user properties
	 * @returns {promise} Resolves with api response.
	 */
	public patchData(data: object) {
		return this.sendRequest(RestCallType.PATCH, '/data', data);
	}

	/**
	 * Get specified scopes from user data
	 * @returns {promise} Resolves with api response.
	 */
	public getData(scope: string) {
		return this.sendRequest(RestCallType.GET, '/data?scope=' + scope, {});
	}

	/**
	 * Sends confirmForgotPassword request
	 * @param {string} confirmationCode
	 * @param {string} password
	 * @returns {Promise<boolean>}
	 */
	public confirmForgotPassword(confirmationCode: string, password: string): Promise<object> {
		return this.authController.getEmail().then((email) => {
			return this.sendRequest(RestCallType.PATCH, '/forgot-password', {
				ConfirmationCode: confirmationCode,
				Password: password,
				Username: email,
			});
		});
	}

	public disableAccount() {
		return this.sendRequest(RestCallType.DELETE, '/disable-account', {});
	}

	/**
	 * Sends change password request
	 * @param {string} oldPassword
	 * @param {string} password
	 * @param {string} passwordConfirmation
	 * @returns {Promise<object>}
	 */
	public changePassword(oldPassword: string, password: string, passwordConfirmation: string): Promise<object> {
		return this.sendRequest(RestCallType.PATCH, '/change-password', { oldPassword, password, passwordConfirmation });
	}

	static getInterestFieldName(type: string) {
		if (type === 'country') {
			return 'interests_countries';
		} else if (type === 'discipline') {
			return 'interests_disciplines';
		} else {
			throw new Error('Invalid interest type only country and discipline supported');
		}
	}
}
