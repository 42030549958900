import { IEventAggregationService, ISubscriber } from '@studyportals/event-aggregation-service-interface';
import { FavouriteRemoved } from '@studyportals/wishlist-service-core';
import { WishlistCache } from '../infrastructure/wishlist-cache';

export class WebSocketFavouriteRemovedSubscriber implements ISubscriber<FavouriteRemoved> {
	constructor(
		private readonly eventAggregationService: IEventAggregationService,
		private wishlistCache: WishlistCache,
	) {}

	async notify(event: FavouriteRemoved): Promise<void> {
		await this.removeFavouriteToCachedWishlist(event);
		const isLocalEvent = this.wishlistCache.isStateHashLocal(event.stateHash);

		this.eventAggregationService.publishTo(
			FavouriteRemoved.EventType,
			new FavouriteRemoved(
				event.userId,
				event.studyId,
				event.eventId,
				event.stateHash,
				new Date(event.dateRemoved),
				isLocalEvent,
			),
		);
	}

	private async removeFavouriteToCachedWishlist(event: FavouriteRemoved): Promise<void> {
		const wishlist = await this.wishlistCache.getWishlist();
		if (wishlist) {
			wishlist.removeFavourite(event.studyId);
			await this.wishlistCache.setWishlist(wishlist);
		}
	}
}
