import { FavouriteApplicationChanged } from '@studyportals/wishlist-service-core';
import { WishlistCache } from '../infrastructure/wishlist-cache';
import { IEventAggregationService } from '@studyportals/event-aggregation-service-interface';
import { IWebSocketService } from '../../../interfaces';
import { WebsocketFavouriteApplicationChangedSubscriber } from './websocket-favourite-application-changed-subscriber';

export class WebsocketFavouriteApplicationChangedAdapter {
	constructor(
		private readonly webSocketService: IWebSocketService,
		private readonly eventAggregationService: IEventAggregationService,
		private wishlistCache: WishlistCache,
	) {}

	public initialize(): void {
		this.webSocketService.subscribeToWebSocketEvent(
			FavouriteApplicationChanged.EventType,
			new WebsocketFavouriteApplicationChangedSubscriber(this.eventAggregationService, this.wishlistCache),
		);
	}
}
