import { IStudentapiDriver } from '../interfaces/i-studentapi-driver';

export class Account {
	public studentAPI: IStudentapiDriver;

	public confirmForgotPassword(confirmationCode: string, password: string): Promise<object> {
		return this.studentAPI.confirmForgotPassword(confirmationCode, password);
	}

	public disableAccount(): Promise<object> {
		return this.studentAPI.disableAccount();
	}

	public changePassword(oldPassword: string, password: string, passwordConfirmation: string) {
		return this.studentAPI.changePassword(oldPassword, password, passwordConfirmation);
	}
}
