import { IEventAggregationService } from '@studyportals/event-aggregation-service-interface';
import config from 'config';
import FetchWrapper from '../../libs/FetchWrapper';
import { WebSocketGateway } from '../web-socket-service/web-socket-gateway';
import { WebSocketService } from '../web-socket-service/web-socket-service';
import AuthController from './auth-controller/auth-controller';
import { StudentapiDriver } from './auth-controller/classes/studentapi-driver';
import { Credentials } from './domain/entities/credentials';
import { TokenBasedSessionFactory } from './domain/factories/token-based-session-factory';
import { TokenBasedSessionRepositoryFactory } from './domain/factories/token-based-session-repository-factory';
import { TokenBasedSessionService } from './domain/services/token-based-session-service';
import { TokenService } from './domain/services/token-service';
import { RefreshTokenFromUrlSubscriber } from './domain/subscribers/refresh-token-from-url-subscriber';
import { RollbarIdentitySubscriber } from './domain/subscribers/rollbar-identity-subscriber';
import { TrackSessionServiceInitializationSubscriber } from './domain/subscribers/track-session-service-initialization-subscriber';
import { GlobalEventAggregationService } from './GlobalEventAggregationService';
import { BrowserSessionStorage } from './infrastructure/browser/browser-session-storage';

declare global {
	interface Window {
		EventAggregationService: IEventAggregationService | undefined;
	}
}

const initialize = (eventAggregationService: IEventAggregationService): void => {
	/* eslint-disable */

	const webSocketGateway = new WebSocketGateway(`${config.wssEndpoint}`);
	const webSocketService = new WebSocketService(webSocketGateway);
	const tokenService = new TokenService(config.tokenServiceBaseUrl);
	const tokenSessionFactory = new TokenBasedSessionFactory(eventAggregationService, tokenService);
	const tokenBasedSessionRepositoryFactory = new TokenBasedSessionRepositoryFactory(
		eventAggregationService,
		tokenSessionFactory,
	);
	const tokenRepository = tokenBasedSessionRepositoryFactory.create();
	const sessionService = new TokenBasedSessionService(tokenRepository, eventAggregationService, tokenService);
	const studentApiDriver = new StudentapiDriver(config.studentApiBaseUrl, FetchWrapper);
	const authController = new AuthController(
		sessionService,
		studentApiDriver,
		eventAggregationService,
		new BrowserSessionStorage(),
	);
	const startTime = new Date();

	webSocketGateway.WebSocketService = webSocketService;
	webSocketGateway.EventAggregationService = eventAggregationService;

	/**
	 * Make objects global
	 */
	window['AuthController'] = authController;
	window['SessionService'] = sessionService;
	window['SessionFactory'] = tokenSessionFactory;

	async function setSessionFromCredentials(credentials: Credentials): Promise<void> {
		const session = tokenSessionFactory.create(credentials);
		await sessionService.setSession(session);
		removeRefreshTokenFromURL();
	}

	function removeRefreshTokenFromURL(): void {
		const url = new URL(location.href);
		url.searchParams.delete('refreshToken');
		window.history.pushState({}, document.title, url.toString());
	}

	/**
	 * Subscribe for login event from AuthenticationService and create session from it
	 */

	document.addEventListener('student_logged_in', (e: any) => {
		const credentials = new Credentials(e?.detail?.accessToken, e?.detail?.refreshToken);
		setSessionFromCredentials(credentials);
	});

	/**
	 * Subscribe for changes to the session credentials and create a session from it across tabs
	 */
	window.addEventListener('storage', async (e) => {
		if (e.key === 'SessionService/credentials') {
			try {
				const storedData = JSON.parse(e.newValue);
				const credentialsSerialized = JSON.parse(storedData.data);
				if (!credentialsSerialized.accessToken || !credentialsSerialized.refreshToken) {
					return;
				}

				const credentials = new Credentials(credentialsSerialized.accessToken, credentialsSerialized.refreshToken);

				const session = await sessionService.getSession();

				if (session !== null) {
					return session.setCredentials(credentials);
				}

				setSessionFromCredentials(credentials);
			} catch (e) {
				console.warn("SessionService: Couldn't create a session with provided credentials.");
			}
		}
	});

	const subscribers = [
		new RefreshTokenFromUrlSubscriber(eventAggregationService, tokenSessionFactory),
		webSocketGateway,
		new TrackSessionServiceInitializationSubscriber(eventAggregationService, startTime),
		new RollbarIdentitySubscriber(eventAggregationService),
	];

	for (const subscriber of subscribers) {
		subscriber.subscribe();
	}

	/**
	 * Initialize objects
	 */
	authController.initialize();
	sessionService.initialize();
	tokenRepository.initialize();
};

let eventAggregationServiceInstance: IEventAggregationService;
if (window.EventAggregationService) {
	eventAggregationServiceInstance = window.EventAggregationService;
} else {
	eventAggregationServiceInstance = GlobalEventAggregationService.initialize();
}

initialize(eventAggregationServiceInstance);
