import { IEvent } from '@studyportals/event-aggregation-service-interface';
import { SessionServiceEventType } from '../enums/event-types';

export class SessionDestroyedEvent implements IEvent {
	public readonly timestamp: Date;
	public readonly eventType = SessionDestroyedEvent.EventType;

	public static EventType = SessionServiceEventType.SESSION_DESTROYED;

	constructor() {
		this.timestamp = new Date();
	}
}
