import { IEventAggregationService, ISubscriber } from '@studyportals/event-aggregation-service-interface';
import { SessionServiceReadyEvent } from '../../../../interfaces';

export class TrackSessionServiceInitializationSubscriber implements ISubscriber<SessionServiceReadyEvent> {
	private readyTime: Date;
	private hasTracked = false;

	constructor(
		private eventAggregationService: IEventAggregationService,
		private startTime: Date,
	) {}

	public subscribe(): void {
		this.eventAggregationService.subscribeTo(SessionServiceReadyEvent.EventType, this, true);
	}

	public notify(event: SessionServiceReadyEvent): void {
		this.readyTime = new Date();

		document.addEventListener('DataTrackerReady', () => {
			this.trackSessionServiceReadyEvent();
		});

		if (window['snowplow'] !== undefined) {
			this.trackSessionServiceReadyEvent();
		}
	}

	private trackSessionServiceReadyEvent(): void {
		if (this.readyTime === null || this.hasTracked) {
			return;
		}

		window['snowplow']('trackStructEvent', {
			category: 'SessionService',
			action: 'initializing',
			label: 'completed',
			value: this.readyTime.getTime() - this.startTime.getTime(),
		});

		this.hasTracked = true;
	}
}
