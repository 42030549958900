import { IEventAggregationService, ISubscriber } from '@studyportals/event-aggregation-service-interface';
import { WishlistOrderChanged } from '@studyportals/wishlist-service-core';
import { WishlistCache } from '../infrastructure/wishlist-cache';

export class WebsocketWishlistOrderChangedSubscriber implements ISubscriber<WishlistOrderChanged> {
	constructor(
		private readonly eventAggregationService: IEventAggregationService,
		private wishlistCache: WishlistCache,
	) {}

	async notify(event: WishlistOrderChanged): Promise<void> {
		await this.setOrderOnCachedWishlist(event);
		const isLocalEvent = this.wishlistCache.isStateHashLocal(event.stateHash);

		this.eventAggregationService.publishTo(
			WishlistOrderChanged.EventType,
			new WishlistOrderChanged(
				event.userId,
				event.targetStudyId,
				event.referenceStudyId,
				event.eventId,
				event.stateHash,
				new Date(event.dateChanged),
				isLocalEvent,
			),
		);
	}

	private async setOrderOnCachedWishlist(event: WishlistOrderChanged): Promise<void> {
		const wishlist = await this.wishlistCache.getWishlist();

		if (wishlist) {
			wishlist.changeOrder(event.targetStudyId, event.referenceStudyId);
			await this.wishlistCache.setWishlist(wishlist);
		}
	}
}
