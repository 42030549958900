import { IEvent } from '@studyportals/event-aggregation-service-interface';
import { ITokenBasedSessionService } from '..';
import { SessionServiceEventType } from '../enums/event-types';

export class SessionServiceReadyEvent implements IEvent {
	public readonly timestamp: Date;
	public readonly eventType = SessionServiceReadyEvent.EventType;
	public readonly sessionService: ITokenBasedSessionService;

	public static EventType: string = SessionServiceEventType.SESSION_SERVICE_READY;

	constructor(sessionService: ITokenBasedSessionService) {
		this.timestamp = new Date();
		this.sessionService = sessionService;
	}
}
