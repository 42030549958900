import { InterestType } from '../enums/interest-type.enum';
import { IServicelayerDriver } from '../interfaces/i-servicelayer-driver';
import { IStudentapiDriver } from '../interfaces/i-studentapi-driver';

export class Disciplines {
	public disciplines: number[];
	public studentAPI: IStudentapiDriver;
	public serviceLayer: IServicelayerDriver;

	public addDisciplines(ids: number[]): Promise<number[]> {
		return this.studentAPI.addDisciplines(ids).then((disciplines) => {
			this.disciplines = disciplines;
			return disciplines;
		});
	}

	public removeDisciplines(ids: number[]): Promise<number[]> {
		return this.studentAPI.removeDisciplines(ids).then((disciplines) => {
			this.disciplines = disciplines;
			return this.disciplines;
		});
	}

	public getDisciplines(): Promise<number[]> {
		return new Promise((resolve, reject) => {
			if (this.disciplines !== undefined && Array.isArray(this.disciplines)) {
				resolve(this.disciplines);
			} else {
				return this.studentAPI.getDisciplines().then((response) => {
					this.disciplines = response === null ? [] : response;
					resolve(this.disciplines);
				});
			}
		});
	}

	public getDisciplinesData(type: InterestType): Promise<object[]> {
		return this.getDisciplines().then((interestIds) => {
			if (Array.isArray(interestIds) && interestIds.length > 0) {
				return this.serviceLayer.getDisciplines(interestIds);
			}
			return [];
		});
	}
}
