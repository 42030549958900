import { FavouriteAssessmentsChanged } from '@studyportals/wishlist-service-core';
import { WishlistCache } from '../infrastructure/wishlist-cache';
import { IEventAggregationService } from '@studyportals/event-aggregation-service-interface';
import { IWebSocketService } from '../../../interfaces';
import { WebsocketFavouriteAssessmentsChangedSubscriber } from './websocket-favourite-assessments-changed-subscriber';

export class WebsocketFavouriteAssessmentsChangedAdapter {
	constructor(
		private readonly webSocketService: IWebSocketService,
		private readonly eventAggregationService: IEventAggregationService,
		private wishlistCache: WishlistCache,
	) {}

	public initialize(): void {
		this.webSocketService.subscribeToWebSocketEvent(
			FavouriteAssessmentsChanged.EventType,
			new WebsocketFavouriteAssessmentsChangedSubscriber(this.eventAggregationService, this.wishlistCache),
		);
	}
}
