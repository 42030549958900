import { Actions, Labels, Tags } from './Tracking/Tracking';
import SPTracker from './Tracking/SPTracker';
import config from 'config';

const sptrackerConfig: any = {
	environment: config.stage ? config.stage : 'dev',
	team: 'xm',
	product: 'StudentJS',
	category: 'Profile',
};

const spTracker = new SPTracker(sptrackerConfig);

export default (error, params: any = {}) => {
	checkDefaults(params);

	if (error) {
		if (error instanceof Error) {
			let { name, message, stack } = error;
			params.eventData = JSON.stringify({
				name,
				message,
				stack,
			});
		} else if (typeof error === 'object') {
			params.eventData = JSON.stringify(error);
		} else if (typeof error === 'string') {
			params.eventData = error;
		} else {
			params.eventData = error.toString();
		}

		if (params.payload) {
			params.payload = JSON.stringify(params.payload);

			if (!params.eventData.toLowerCase().includes('password') && !params.payload.toLowerCase().includes('password')) {
				params.eventData += params.payload;
			}
			delete params.payload;
		}
	}

	spTracker.track(params);

	throw error;
};

function checkDefaults(params) {
	if (typeof params.action === 'undefined') {
		params.action = Actions.General;
	}

	if (typeof params.label === 'undefined') {
		params.label = Labels.JSError;
	}

	if (typeof params.tags === 'undefined') {
		params.tags = [Tags.Error];
	}
}
