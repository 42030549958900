import { Actions, Labels } from '../../../../libs/Tracking/Tracking';
import AuthController from '../../../session-management/auth-controller/auth-controller';
import { InterestType } from '../../enums/interest-type.enum';
import { RestCallType } from '../../enums/restcall-type.enum';
import { tCacheStatus } from '../../index.d';
import { IServicelayerDriver } from '../../interfaces/i-servicelayer-driver';

export class ServicelayerDriver implements IServicelayerDriver {
	private baseUrl: string;
	private authController: AuthController;
	private fetch: Function;
	private countries: Object;
	private disciplines: Object;
	private studies: Object;
	private organisations: Object;
	private trackError: Function;
	private retrievingFavourites: Promise<object[]> = null;

	constructor(baseurl: string, authController: AuthController, fetch: Function, trackError: Function) {
		this.baseUrl = baseurl;
		this.authController = authController;
		this.fetch = fetch;
		this.countries = {};
		this.disciplines = {};
		this.studies = {};
		this.organisations = {};
		this.trackError = trackError;
	}

	public clearCache(type: string = '') {
		let cleared = true;
		switch (type) {
			case 'countries':
				this.countries = {};
				break;
			case 'disciplines':
				this.disciplines = {};
				break;
			case 'interests':
				this.countries = {};
				this.disciplines = {};
				break;
			default:
				cleared = false;
				break;
		}
		return cleared;
	}

	private addResourceType(type: string, resources: any[]): object[] {
		return resources.map((x) => {
			x.type = type;
			return x;
		});
	}

	public getDisciplines(ids: number[]): Promise<object[]> {
		return this.getResources('disciplines', ids);
	}

	public getInterests(type: string, ids: number[]): Promise<object[]> {
		type = type === InterestType.Country ? 'countries' : 'disciplines';
		return this.getResources(type, ids);
	}

	private getResources(type: string, ids: number[]): Promise<object[]> {
		let { cached, notCached } = this.getResourceCacheState(type, ids);

		if (notCached.length === 0) {
			return Promise.resolve(this.mergeResourcesFromCacheWithNew(type, cached, []));
		}

		return this.sendRequest(RestCallType.GET, `/${type}?ids=${notCached.join(',')}`).then((resources) => {
			if (Array.isArray(resources)) {
				resources.forEach((resource) => {
					this[type][resource.id] = resource;
				});
				return this.mergeResourcesFromCacheWithNew(type, cached, resources);
			}
			return [];
		});
	}

	private getResourceCacheState(type: string, ids: number[]): tCacheStatus {
		let cached = [];
		let notCached = [];
		ids.forEach((id) => {
			if (this.isResourceInCache(type, id)) {
				cached.push(id);
			} else {
				notCached.push(id);
			}
		});
		return { cached, notCached };
	}

	private mergeResourcesFromCacheWithNew(type: string, cachedIds: number[], newResources: object[]): object[] {
		let cachedResources = [];
		cachedIds.forEach((cachedId) => {
			cachedResources.push(this[type][cachedId.toString()]);
		});
		cachedResources = cachedResources.concat(newResources);
		return cachedResources;
	}

	private isResourceInCache(type: string, searchId: number): Boolean {
		return (
			typeof Object.keys(this[type]).find((id) => {
				return id === searchId.toString();
			}) !== 'undefined'
		);
	}

	public sendRequest(method: string, uri: string, data: object = null) {
		return this.authController
			.getAccessToken()
			.then((jwt) => {
				let options = {
					method,
				};

				options['headers'] = {
					'Content-Type': 'application/json',
					Authorization: jwt,
				};

				options['credentials'] = 'omit';

				if (method.toUpperCase() !== RestCallType.GET && data) {
					options['body'] = JSON.stringify(data);
				}

				return this.fetch(this.baseUrl + uri, options)
					.then((response) => response.json())
					.catch((error) =>
						this.trackError(error, {
							action: Actions.SLRequest,
							label: Labels.SLError,
						}),
					);
			})
			.catch((error) =>
				this.trackError(error, {
					action: Actions.JWTAuth,
					label: Labels.JWTAuthError,
				}),
			);
	}
}
