import { ICredentials } from '../../../../interfaces';
import { ServerTime } from '../../infrastructure/server-time/server-time';
import { IServerTime } from '../../infrastructure/server-time/server-time.interface';
import { InvalidAccessTokenError } from '../errors/invalid-access-token-error';
import { CredentialsPayload } from './credentials-payload';

export class Credentials implements ICredentials {
	private serverTime: IServerTime = new ServerTime();

	public static create(credentials: { accessToken: string; refreshToken: string }): Credentials {
		return new Credentials(credentials.accessToken, credentials.refreshToken);
	}

	constructor(
		public readonly accessToken: string = '',
		public readonly refreshToken: string,
	) {}

	public isNewerThan(credentials: Credentials) {
		const credentialsPayload = this.getPayload();
		const comparingPayload = credentials.getPayload();

		return credentialsPayload.exp > comparingPayload.exp;
	}

	public getPayload(): CredentialsPayload {
		try {
			const tokenPayloadUrlSafe = this.accessToken.split('.')[1];
			const tokenPayloadBase64 = tokenPayloadUrlSafe.replace('-', '+').replace('_', '/');
			return JSON.parse(window.atob(tokenPayloadBase64));
		} catch (error) {
			console.log(error);
			throw new InvalidAccessTokenError('Not a valid JWT.');
		}
	}

	public async isExpired(): Promise<boolean> {
		if (this.accessToken === null) {
			return true;
		}

		// 5 seconds before the token expire, we consider it expired
		const expiryTime = this.getPayload().exp - 5;
		const serverTime = await this.serverTime.now();

		return expiryTime <= serverTime;
	}

	public toJSON() {
		return {
			accessToken: this.accessToken,
			refreshToken: this.refreshToken,
		};
	}
}
