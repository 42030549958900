import { IEventAggregationService, ISubscriber } from '@studyportals/event-aggregation-service-interface';
import { FavouriteAdded } from '@studyportals/wishlist-service-core';
import { WishlistCache } from '../infrastructure/wishlist-cache';

export class WebSocketFavouriteAddedSubscriber implements ISubscriber<FavouriteAdded> {
	constructor(
		private readonly eventAggregationService: IEventAggregationService,
		private readonly wishlistCache: WishlistCache,
	) {}

	async notify(event: FavouriteAdded): Promise<void> {
		await this.wishlistCache.clearCache();
		const isLocalEvent = this.wishlistCache.isStateHashLocal(event.stateHash);

		this.eventAggregationService.publishTo(
			FavouriteAdded.EventType,
			new FavouriteAdded(
				event.userId,
				event.studyId,
				event.eventId,
				event.stateHash,
				new Date(event.dateAdded),
				isLocalEvent,
			),
		);
	}
}
