import { IEventAggregationService, ISubscriber } from '@studyportals/event-aggregation-service-interface';
import {
	ISessionService,
	ITokenBasedSession,
	IWebSocketService,
	SessionCreatedEvent,
	SessionDestroyedEvent,
	SessionServiceReadyEvent,
	WebsocketServiceReadyEvent,
} from '../../interfaces';
import { RefreshTokenError } from '../session-management/domain/errors/refresh-token-error';
import { WebSocketGatewaySubscriber } from './web-socket-gateway-subscriber';
import { IWebSocketGateway } from './web-socket-gateway.interface';

export class WebSocketGateway
	implements IWebSocketGateway, ISubscriber<SessionServiceReadyEvent | SessionCreatedEvent>
{
	private sessionService: ISessionService;
	private eventAggregationService: IEventAggregationService;
	private webSocketService: IWebSocketService;
	private socket: WebSocket;

	private subscribers: WebSocketGatewaySubscriber<any>[] = [];

	constructor(public readonly wssEndpoint: string) {}

	public set EventAggregationService(eventAggregationService: IEventAggregationService) {
		this.eventAggregationService = eventAggregationService;
	}

	public set WebSocketService(webSocketService: IWebSocketService) {
		this.webSocketService = webSocketService;
	}

	public async initialize(sessionService: ISessionService): Promise<void> {
		this.eventAggregationService.publishTo(
			WebsocketServiceReadyEvent.EventType,
			new WebsocketServiceReadyEvent(this.webSocketService),
		);
		this.sessionService = sessionService;

		if (await this.isUserLoggedIn()) {
			await this.connect();
		}
	}

	public subscribe(): void {
		this.eventAggregationService.subscribeTo(SessionServiceReadyEvent.EventType, this, true);
		this.eventAggregationService.subscribeTo(SessionCreatedEvent.EventType, this);
	}

	public async notify(event: SessionServiceReadyEvent | SessionCreatedEvent): Promise<void> {
		if (event.eventType === SessionServiceReadyEvent.EventType) {
			await this.initialize((event as SessionServiceReadyEvent).sessionService);
		}
		if (event.eventType === SessionCreatedEvent.EventType) {
			await this.connect();
		}
	}

	public async connect(): Promise<void> {
		try {
			const accessToken = await this.getAccessToken();
			const url = window.location && window.location.href ? encodeURIComponent(window.location.href) : '';
			this.socket = new WebSocket(`${this.wssEndpoint}?token=${accessToken}&url=${url}`);
			this.addWebsocketEventListeners();
		} catch (e) {
			if (!(e instanceof RefreshTokenError)) {
				throw e;
			}

			this.eventAggregationService.publishTo(SessionDestroyedEvent.EventType, new SessionDestroyedEvent());
		}
	}

	public addSubscriber(subscriber: WebSocketGatewaySubscriber<any>): void {
		this.subscribers.push(subscriber);
	}

	private async isUserLoggedIn(): Promise<boolean> {
		const session = await this.sessionService.getSession();
		return session !== null;
	}

	private addWebsocketEventListeners(): void {
		this.socket.addEventListener('message', (message) => {
			this.onMessage(JSON.parse(message.data));
		});
		this.socket.addEventListener('close', () => {
			this.reconnect();
		});
	}

	private reconnect(): void {
		setTimeout(() => {
			return this.connect();
		}, 5000);
	}

	private async getAccessToken(): Promise<string> {
		const session = (await this.sessionService.getSession()) as ITokenBasedSession;
		return session.getAccessToken();
	}

	private onMessage(message: any): void {
		this.subscribers.filter((s) => s.canHandle(message.eventType)).forEach((s) => s.handle(message));
	}
}
