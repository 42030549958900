import { IStudentapiDriver } from '../interfaces/i-studentapi-driver';

export class Data {
	public studentAPI: IStudentapiDriver;

	public getScope(scope: string): Promise<object> {
		return this.studentAPI.getData(scope);
	}

	public getScopes(scopes: string[]): Promise<object> {
		return this.getScope(scopes.join(','));
	}

	public setValue(key: string, value: any): Promise<object> {
		let data = {};
		data[key] = value;

		return this.studentAPI.patchData(data);
	}

	public setValues(data: object): Promise<object> {
		return this.studentAPI.patchData(data);
	}

	public setGPA(grade_type: string, grade_value: any): Promise<Object> {
		let data = {
			gpa: {
				[grade_type]: grade_value,
				current_type: grade_type,
			},
		};

		return this.studentAPI.patchData(data);
	}

	public setName(name: string) {
		let firstSpace = name.indexOf(' ', 0);
		let first_name;
		let last_name;

		if (firstSpace !== -1) {
			first_name = name.substring(0, firstSpace);
			last_name = name.substring(firstSpace).trim();
		} else {
			first_name = name;
			last_name = null;
		}

		let data = {
			name,
			first_name,
			last_name,
		};

		return this.studentAPI.patchData(data);
	}
}
