import { IEvent } from '@studyportals/event-aggregation-service-interface';
import { WebSocketEventType } from '../enums/event-type';
import { IWebSocketService } from '../web-socket-service.interface';

export class WebsocketServiceReadyEvent implements IEvent {
	public readonly timestamp: Date;
	public readonly eventType = WebsocketServiceReadyEvent.EventType;

	public static EventType: string = WebSocketEventType.WEBSOCKET_SERVICE_READY;

	/**
	 * @deprecated Use webSocketService instead
	 */
	public readonly eventAggregationService: IWebSocketService;

	constructor(public readonly webSocketService: IWebSocketService) {
		this.eventAggregationService = webSocketService;
		this.timestamp = new Date();
	}
}
