import fetch, { Request, RequestInit, Response } from 'node-fetch';
import { Credentials } from '../entities/credentials';
import { LogoutError } from '../errors/logout-error';
import { RefreshTokenError } from '../errors/refresh-token-error';
import { ITokenService } from '../interfaces/token-service.interface';

export enum LogoutMode {
	Session = 'session',
	Subject = 'subject',
}

export class TokenService implements ITokenService {
	constructor(private baseUrl: string) {}

	private signRequest(request: Request, token: string) {
		request.headers.append('Authorization', token);
	}

	private getRefreshTokenRequestOptions(refreshToken: string): Request {
		const request = new Request(`${this.baseUrl}/refresh`, {
			method: 'GET',
		});
		this.signRequest(request, refreshToken);
		return request;
	}

	private getLogoutTokenRequestOptions(accessToken: string, mode: LogoutMode): Request {
		const request = new Request(`${this.baseUrl}/revoke?mode=${mode}`, {
			method: 'DELETE',
		});
		this.signRequest(request, accessToken);
		return request;
	}

	async refreshCredentials(refreshToken: string): Promise<Credentials> {
		const response: Response = await this.fetch(this.getRefreshTokenRequestOptions(refreshToken));
		if (response.ok) {
			const parsedResponse = await response.json();
			return new Credentials(parsedResponse.accessToken, parsedResponse.refreshToken);
		} else if (response.status === 401) {
			throw new RefreshTokenError('Refresh token unauthorized');
		}
		throw new RefreshTokenError('Error while refreshing');
	}

	async logoutSession(accessToken: string): Promise<void> {
		const response: Response = await this.fetch(this.getLogoutTokenRequestOptions(accessToken, LogoutMode.Session));
		if (!response.ok) {
			throw new LogoutError();
		}
	}

	async logoutSubject(accessToken: string): Promise<void> {
		const response: Response = await this.fetch(this.getLogoutTokenRequestOptions(accessToken, LogoutMode.Subject));
		if (!response.ok) {
			throw new LogoutError();
		}
	}

	private fetch(url: string | Request, init?: RequestInit): Promise<Response> {
		return fetch(url, init);
	}
}
